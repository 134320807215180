body {
    overflow-x: scroll;
}

.openingYoutube {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 5px;
}

.three{
    width: 1127px;
}

.openingDisplay {
    display: flex;
    text-align:center;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.compressed {
    height: 1vh;
}

.mainWindow {
    width: 1127px;
    padding: 5px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 5px;
    text-align: center;
}

.mainMenuBorder {
    display: flex;
}

.graphWindow {
    width: 1050px;
    padding: 5px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 5px;
    text-align: center;
}

.grid {
    display: grid;
}
.gridElement {
    width: 200px;
    height: 360px;
    margin: 5px;
    padding: 5px;
}
.whitespace {
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}
.p11 {
    width: 340px;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 1;
}
.p12 {
    width: 340px;
    text-align: center;
    grid-column-start: 2;
    grid-column-end: 2;
}
.p13 {
    text-align: center;
    grid-column-start: 3;
    grid-column-end: 3;
}
.p14 {
    text-align: center;
    grid-column-start: 4;
    grid-column-end: 4;
}
.p15 {
    text-align: center;
    grid-column-start: 5;
    grid-column-end: 5;
}
.p16 {
    text-align: center;
    grid-column-start: 6;
    grid-column-end: 6;
}
.p17 {
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 7;
}

.p18 {
    text-align: center;
    grid-column-start: 8;
    grid-column-end: 8;
}

.p19 {
    text-align: center;
    grid-column-start: 9;
    grid-column-end: 9;
}

.p110 {
    text-align: center;
    grid-column-start: 10;
    grid-column-end: 10;
}

.p111 {
    text-align: center;
    grid-column-start: 11;
    grid-column-end: 11;
}

.mediumImage {
    width:400px;
}

.workstationImage {
    grid-column-start: 2;
    grid-column-end: 2;
}
.workstationDescription1 {
    grid-column-start: 1;
    grid-column-end: 1;
    width: 320px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 5px;
    text-align: left;
    padding: 10px;
}
.workstationDescription2 {
    grid-column-start: 2;
    grid-column-end: 2;
    width: 320px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 5px;
    text-align: left;
    padding: 10px;
}

.quality1 {
    grid-column-start: 1;
    grid-column-end: 1;
    width: 280px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    text-align: left;
    padding: 2px;
    max-height: 768px;
    overflow-y: auto;
}
.quality2 {
    grid-column-start: 2;
    grid-column-end: 2;
    width: 270px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    text-align: left;
    padding: 2px;
    max-height: 768px;
    overflow-y: auto;
}
.quality3 {
    grid-column-start: 3;
    grid-column-end: 3;
    width: 270px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    text-align: left;
    padding: 2px;
    max-height: 768px;
    overflow-y: auto;
}

.quality4 {
    grid-column-start: 4;
    grid-column-end: 4;
    width: 280px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 5px;
    text-align: left;
    padding: 10px;
    max-height: 768px;
    overflow-y: auto;
}

.poContent {
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 5px;
    text-align: left;
    padding: 10px;
}

.poContent:hover{
    background-color: #f8f8f9;
}

.selected {
    background-color: lightblue;
}

.waiting {
    border-color: lightgray;
    border-width: 1px;
}

.partWaiting {
    border-color: khaki;
    border-width: 5px;    
}

.active {
    border-color: green;
    border-width: 5px;
}

.reserved {
    border-color: orange;
    border-width: 5px;
}

.editWindow {
    grid-column-start: 2;
    grid-column-end: 6;
    width: 846px;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 1px;
    text-align: left;
    padding: 5px;
    max-height: 768px;
    overflow-y: auto;
}

.editWindow1 {
    width: 990px;
    text-align: left;
}

.input {
    display: inline-block;
    text-align: left;
    padding: 8px 10px;
    width: 300px;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    border-radius: 5px;
    color: black;
    font-size: 16px;
    font-weight: 400;
    background-color: white;
}

.chat {
    width: 600px;
}

.small {
    width: 110px;   
}

.medium {
    width: 210px;
}

.smallFont{
    font-size:12px;
}

.button {
    display: inline-block;
    text-align: center;
    padding: 6px 25px;
    width: auto;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
    border-radius: 5px;
    color: black;
    font-size: 14px;
    font-weight: 450;
    background-color: whitesmoke;
}


.button:hover {
    background-color: lightblue;
    color: white;
    border-color: lightblue;
}

.spacing {
    padding: 10px 30px;
    color: black;
    font-size: 16px;
    font-weight: 400;
}

.bold {
    font-weight: 550;
}

.error {
    background-color: red;
}

.smallerStatus {
    width: 835px;
}

.chat-container {
    width: 100%;
    max-width: 1050px; /* Adjust based on your design */
    margin: 0 auto;
    background-color: #f0f0f0; /* Light gray background for chat area */
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    height: 75vh; /* You might want to set this based on available space */
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff; /* White message bubble */
    display: flex;
    align-items: baseline;
    word-wrap: break-word;
}

.timestamp {
    color: #777;
    font-size: 0.8em;
    margin-right: 5px;
}

.message-text {
    flex-grow: 1;
    white-space: pre-wrap; /* Preserves whitespace formatting */
}

/* Alternating message colors for better readability */
.message:nth-child(even) {
    background-color: #e8e8e8; /* Slightly different shade for even messages */
}
